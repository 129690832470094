import React, { useEffect, useState } from "react";
import "./BarCodeScanner.scss";
import ProductCard from "../ProductCard/ProductCard";
import { API } from "../../constants/api";
import { Product } from "../../interfaces/IProduct";
import { Html5QrcodeScan } from "../UI/scanner/scanner";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const formatDate = (date: {
  getDate: () => any;
  getMonth: () => number;
  getFullYear: () => any;
  getHours: () => any;
  getMinutes: () => any;
  getSeconds: () => any;
}) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;
};

export const BarcodeScanner: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const username = localStorage.getItem("username") || "unknown";
  const onNewScanResult = async (decodedText: string) => {
    let formattedText = decodedText;
    if (decodedText.length < 13) {
      formattedText = decodedText.padStart(13, "0");
    }

    try {
      const response = await fetch(
        `${API}/products/get_by_barcode/${formattedText}`
      );
      const data = await response.json();

      const newProduct: Product = {
        id: data.product.id,
        article: data.product.article,
        title: data.product.title,
        image: data.product.image,
        amount: 1,
        price: data.product.price,
      };

      setProducts((prevProducts) => {
        const exists = prevProducts.find(
          (product) => product.id === newProduct.id
        );
        if (!exists) {
          toast.success("Успешно отсканированно", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
          return [...prevProducts, newProduct];
        }
        return prevProducts;
      });
    } catch (error) {
      console.error("Error fetching product data: ", error);
    }
  };

  const handleDelete = (id: number) => {
    setProducts(products.filter((product) => product.id !== id));
    toast.success("Успешно удален товар", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const handleIncreaseAmount = (id: number) => {
    setProducts(
      products.map((product) =>
        product.id === id ? { ...product, amount: product.amount + 1 } : product
      )
    );
  };

  const handleDecreaseAmount = (id: number) => {
    setProducts(
      products.map((product) =>
        product.id === id && product.amount > 1
          ? { ...product, amount: product.amount - 1 }
          : product
      )
    );
  };

  const submit = async () => {
    const data = {
      number: username,
      items: products,
      datetime: formatDate(new Date()),
    };
    console.log(data);
    const response = await fetch(`${API}/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
     
      
    });
    
    toast.success("Отправленно в продажи", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
    return response;
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("username");
    toast.success("LocalStorage очищен", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
    navigate("/login");
  };

  const clearProducts = () => {
    setProducts([]);
    toast.success("Список продуктов очищен", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // Заменяем текст на русский язык после рендеринга компонента
    const intervalId = setInterval(() => {
      const startButton = document.getElementById(
        "html5-qrcode-button-camera-start"
      );
      if (startButton) {
        startButton.textContent = "Начать сканирование";
      }

      const stopButton = document.getElementById(
        "html5-qrcode-button-camera-stop"
      );
      if (stopButton) {
        stopButton.textContent = "Остановить сканирование";
      }

      const torchButton = document.getElementById(
        "html5-qrcode-button-torch"
      );
      if (torchButton) {
        torchButton.textContent = "Фонарик";
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="header">
        <h1>DDW QR</h1>
        <div className={`header_btn ${menuOpen ? "open" : ""}`}>
          <button className="burger-menu" onClick={toggleMenu}>
            ☰
          </button>
          <div className={`menu ${menuOpen ? "open" : ""}`}>
            <button className="clear-products-button" onClick={clearProducts}>
              Новый клиент
            </button>
            <button
              className="clear-local-storage-button"
              onClick={clearLocalStorage}
            >
              Выход
            </button>
          </div>
        </div>
      </div>

      <Html5QrcodeScan
        fps={10}
        qrCodeSuccessCallback={onNewScanResult}
        qrCodeErrorCallback={(e) => console.log(e)}
        qrbox={{ width: 250, height: 100 }} // Настроим ширину и высоту для штрих-кодов
        showTorchButtonIfSupported={true}
        defaultZoomValueIfSupported={2}
        videoConstraints={{
          width: { ideal: 1280 },
          height: { ideal: 720 },
          aspectRatio: 1.6, // 16:9 aspect ratio для ширины
          facingMode: "environment",
          ...({ focusMode: "continuous" } as any),
          advanced: [{ focusMode: "continuous" } as any],
        }}
      />
      {products.map((product) => (
        <ProductCard
          key={product.id}
          id={product.id}
          article={product.article}
          title={product.title}
          image={product.image}
          amount={product.amount}
          onDelete={handleDelete}
          onIncreaseAmount={handleIncreaseAmount}
          onDecreaseAmount={handleDecreaseAmount}
          price={product.price}
        />
      ))}

      <button className="submit-button" onClick={submit}>
        Отправить
      </button>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
};

export default BarcodeScanner;
