// src/App.tsx
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import QRPage from "./container/qr/qr";
import Login from "./container/login/login";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";



const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<QRPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
