import React from "react";
import "./ProductCard.scss";

interface ProductCardProps {
  id: number;
  article: string;
  title: string;
  image: string;
  amount: number;
  price: number;
  onDelete: (id: number) => void;
  onIncreaseAmount: (id: number) => void;
  onDecreaseAmount: (id: number) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  id,
  article,
  title,
  image,
  amount,
  onDelete,
  onIncreaseAmount,
  onDecreaseAmount,
  price
}) => {
  return (
    <div className="product-card">
      <div className="product-details">
        <h2 className="product-title">{title}</h2>
        <p className="product-article">{article}</p>
        <p className="product-price">{price}</p>
        <div className="product-amount">
          <button onClick={() => onDecreaseAmount(id)}>-</button>
          <span>{amount}</span>
          <button onClick={() => onIncreaseAmount(id)}>+</button>
        </div>
      </div>
      <div className="product-image">
        <img src={image} alt={title} />
      </div>
      <button className="delete-button" onClick={() => onDelete(id)}>
        X
      </button>
    </div>
  );
};

export default ProductCard;
