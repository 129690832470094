// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8ff;
}

.login-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  margin-bottom: 1rem;
  color: #004080;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #004080;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #004080;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #004080;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #003060;
}`, "",{"version":3,"sources":["webpack://./src/container/login/Login.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AACJ;;AAEE;EACE,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEE;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,cAAA;EACA,qBAAA;EACA,cAAA;AACJ;;AAEE;EACE,WAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEE;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,iCAAA;AACJ;;AAEE;EACE,yBAAA;AACJ","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f0f8ff;\n  }\n  \n  .login-form {\n    background-color: #fff;\n    padding: 2rem;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 400px;\n  }\n  \n  .login-title {\n    margin-bottom: 1rem;\n    color: #004080;\n    text-align: center;\n  }\n  \n  .form-group {\n    margin-bottom: 1rem;\n  }\n  \n  .form-group label {\n    display: block;\n    margin-bottom: 0.5rem;\n    color: #004080;\n  }\n  \n  .form-group input {\n    width: 100%;\n    padding: 0.5rem;\n    border: 1px solid #004080;\n    border-radius: 4px;\n    font-size: 1rem;\n  }\n  \n  .login-button {\n    width: 100%;\n    padding: 0.75rem;\n    background-color: #004080;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .login-button:hover {\n    background-color: #003060;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
