import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginUsername = ["827", "797", "802", "776", "825", "660", "769", "840", "869", "866", "761", "838", "858", "867"];
  const passwords = [
    "12345678",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
    "qwerty12345",
  ];

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    loginUsername.forEach((user, i) => {
      if (username === user && passwords[i] === password) {
        localStorage.setItem("access", "true");
        localStorage.setItem("username", username);
        navigate("/");
      }
    });
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2 className="login-title">DDW</h2>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
