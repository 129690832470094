// src/app/qr/page.tsx

import BarcodeScanner from "../../components/BarCodeScanner/BarCodeScanner";

const QRPage = () => {
  return (
    <div>
      <BarcodeScanner />
    </div>
  );
};

export default QRPage;
